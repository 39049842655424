import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import logo from "../../assets/logo.png";

const numero = Math.floor(Math.random() * (6 - 1) + 1);
const imag = "https://publimed.net/"+numero+".jpg"

export const Login = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("userPublimed");

  useEffect(() => {
    if (localStorage.getItem("userPublimed")) {
      navigate("/home");
    }
  });

  function Copyright(props) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" /*href="https://mui.com/"*/>Publimed</Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const defaultTheme = createTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  

  const handleSubmit = () => {
    // if (email.split("@")[0] !== password) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Las credenciales no pertenecen a ningún usuario",
    //   });
    //   return;
    // }
    fetch(
      "https://publimed.net/API/usuarios.php/?usuario=" +
        email +
        "&contrasena=" +
        password,
      {
        method: "GET",
      }
    )
      .then((res) => res.text())
      .then((res) => {
        console.log(JSON.parse(res));
        if (JSON.parse(res).length > 0) {
          localStorage.setItem(
            "userPublimed",
            JSON.stringify({
              email: email,
              password: password,
            })
          );
          navigate("/home");
        } else {
          Swal.fire({ icon: "error", text: "Credenciales incorrectas" });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(user);
  };

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: "url("+imag+")",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={logo} alt="logo" width={300} />
              <br />
              <Typography component="h1" variant="h3">
                <strong>Login</strong>
              </Typography>
              <form>
                <Box sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Usuario"
                    id="userPublimed"
                    name="userPublimed"
                    autoComplete="userPublimed"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    label="Contraseña"
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <Button
                    fullWidth
                    disabled={
                      email.trim().length === 0 || password.trim().length === 0
                    }
                    onClick={() => handleSubmit()}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Ingresar
                  </Button>
                  <Copyright sx={{ mt: 5 }} />
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
      {/* )} */}
    </div>
  );
};
