import { BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import "./App.css";

import { Home } from "./pages/home/Home";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import { Login } from "./pages/login/Login";
import { Ejemplo } from "./pages/home/Nav";
import { Box } from "@mui/material";

const App =() => {
  return (
    <BrowserRouter>
      <Box sx={{ display: "flex" }}>
        <Ejemplo />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Routes>
            <Route element={<ProtectedRoute redirectPath="/login" />}>
              {/* <Route path="" element={<Home />} /> */}
              <Route path="home" element={<Home />} />
            </Route>
            <Route path="login" element={<Login />} />
            <Route key={12} path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;
