import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// import Badge from "@mui/material/Badge";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import { useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // width: theme.spacing(7),
      width: 0,
      [theme.breakpoints.up("sm")]: {
        // width: theme.spacing(9),
        width: 0,
      },
    }),
  },
}));

export const Ejemplo = () => {
  const location = useLocation();
  console.log();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      {!location.pathname.includes("login") && (
        <>
          <Box sx={{ display: "flex", backgroundColor: "gray" }}>
            <AppBar
              position="absolute"
              open={open}
              sx={{ backgroundColor: "#428bd4" }}
            >
              <Toolbar
                sx={{
                  pr: "24px",
                }}
              >
                {/* <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    // marginRight: "36px",
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton> */}
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  <strong>PUBLIMED CLIENTES</strong>
                </Typography>
                {/* <IconButton color="inherit">
                  <Badge badgeContent={4} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton> */}

                <IconButton
                  color="inherit"
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <AccountCircleIcon
                    fontSize="large"
                    // onClick={()=> {
                    //   localStorage.removeItem("userPublimed");
                    //   window.location.reload();
                    // }}
                  />
                </IconButton>

                <Menu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                >
                  <MenuItem sx={{ margin: "10px" }}>
                    {localStorage.getItem("userPublimed") &&
                      JSON.parse(
                        localStorage.getItem("userPublimed")
                      ).email.toUpperCase()}
                  </MenuItem>
                  <MenuItem
                    sx={{ margin: "10px" }}
                    onClick={() => {
                      setAnchorEl(null);
                      localStorage.removeItem("userPublimed");
                      window.location.reload();
                    }}
                  >
                    Salir
                  </MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
          </Box>
        </>
      )}
    </>
  );
};
