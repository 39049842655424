import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Typography } from "@mui/material";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import GradingIcon from "@mui/icons-material/Grading";
import DescriptionIcon from "@mui/icons-material/Description";

export const Otros = ({ estado }) => {
  return (
    <Timeline
      position="rigth"
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 1,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector
            sx={{ bgcolor: estado > 0 ? "darkGreen" : "darkOrange" }}
          />
          <TimelineDot
            sx={{ backgroundColor: estado > 0 ? "darkGreen" : "darkOrange" }}
          >
            <AttachMoneyIcon />
          </TimelineDot>
          <TimelineConnector
            sx={{ bgcolor: estado > 0 ? "darkGreen" : "darkOrange" }}
          />
        </TimelineSeparator>
        <TimelineContent sx={{ my: "auto" }}>
          <Typography component="span">
            <strong>Procesando Pago</strong>
            <br />- {estado > 0 ? "Completado" : "Pendiente"}
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector
            sx={{ bgcolor: estado > 1 ? "darkGreen" : "darkOrange" }}
          />
          <TimelineDot
            sx={{ backgroundColor: estado > 1 ? "darkGreen" : "darkOrange" }}
          >
            <DescriptionIcon />
          </TimelineDot>
          <TimelineConnector
            sx={{ bgcolor: estado > 1 ? "darkGreen" : "darkOrange" }}
          />
        </TimelineSeparator>
        <TimelineContent sx={{ my: "auto" }}>
          <Typography component="span">
            <strong>Elaboración</strong>
            <br />- {estado > 1 ? "Completado" : "Pendiente"}
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector
            sx={{ bgcolor: estado > 2 ? "darkGreen" : "darkOrange" }}
          />
          <TimelineDot
            sx={{ backgroundColor: estado > 2 ? "darkGreen" : "darkOrange" }}
          >
            <NoteAltIcon />
          </TimelineDot>
          <TimelineConnector
            sx={{ bgcolor: estado > 2 ? "darkGreen" : "darkOrange" }}
          />
        </TimelineSeparator>
        <TimelineContent sx={{ my: "auto" }}>
          <Typography component="span">
            <strong>Edición</strong>
            <br />- {estado > 2 ? "Completado" : "Pendiente"}
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector
            sx={{ bgcolor: estado > 3 ? "darkGreen" : "darkOrange" }}
          />
          <TimelineDot
            sx={{ backgroundColor: estado > 3 ? "darkGreen" : "darkOrange" }}
          >
            <GradingIcon />
          </TimelineDot>
          <TimelineConnector
            sx={{ bgcolor: estado > 3 ? "darkGreen" : "darkOrange" }}
          />
        </TimelineSeparator>
        <TimelineContent sx={{ my: "auto" }}>
          <Typography component="span">
            <strong>Revisión</strong>
            <br />- {estado > 3 ? "Completado" : "Pendiente"}
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector
            sx={{ bgcolor: estado > 4 ? "darkGreen" : "darkOrange" }}
          />
          <TimelineDot
            sx={{ backgroundColor: estado > 4 ? "darkGreen" : "darkOrange" }}
          >
            <LocalPostOfficeIcon />
          </TimelineDot>
          <TimelineConnector
            sx={{ bgcolor: estado > 4 ? "darkGreen" : "darkOrange" }}
          />
        </TimelineSeparator>
        <TimelineContent sx={{ my: "auto" }}>
          <Typography component="span">
            <strong>Entrega</strong>
            <br />- {estado > 4 ? "Completado" : "Pendiente"}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
