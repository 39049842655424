import { Box, Button, Grid, Paper, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Libro } from "./Libro";
import { Certificado } from "./Certificado";
import { Otros } from "./Otros";

export const Home = () => {
  const navigate = useNavigate();

  const [productos, setProductos] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("userPublimed")) {
      obtenerUsuario(JSON.parse(localStorage.getItem("userPublimed")).email);
    }
  }, []);

  async function obtenerUsuario(email) {
    await fetch("https://publimed.net/API/usuarios.php/?usuario=" + email, {
      method: "GET",
    })
      .then((res) => res.text())
      .then((res) => {
        console.log(JSON.parse(res));
        if (JSON.parse(res).length === 0) {
          localStorage.removeItem("userPublimed");
          navigate("/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    cargarProductos(email);
  }

  function cargarProductos(email) {
    fetch("https://publimed.net/API/compra.php/?usuario=" + email, {
      method: "GET",
    })
      .then((res) => res.text())
      .then((res) => {
        console.log(JSON.parse(res));
        setProductos(JSON.parse(res));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Toolbar />
      <Box px={2} py={2}>
        <Paper elevation={2} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            {" "}
            <strong>Mis Compras</strong>{" "}
          </Typography>
          <br />

          <Grid container spacing={5} sx={{ padding: "10px" }}>
            {productos
              ? productos.map((val) => (
                  <Grid item xs={12} md={6} lg={3} key={val.id}>
                    <Paper elevation={3} sx={{ padding: "20px" }}>
                      <Typography align="center">
                        {" "}
                        <strong> {val.tipo} </strong>
                      </Typography>
                      <br />
                      <Typography>
                        {" "}
                        <strong> Producto: </strong> {val.nombre}{" "}
                        {val.especialidad.length !== 0 && (
                          <>
                            <br /> <strong>Especialidad</strong>:{" "}
                            {val.especialidad}
                          </>
                        )}
                        {val.tema.length !== 0 && (
                          <>
                            <br /> <strong>Tema</strong>: {val.tema}
                          </>
                        )}
                        {val.tiempo.length !== 0 && (
                          <>
                            <br /> <strong>Tiempo</strong>: {val.tiempo}
                          </>
                        )}
                      </Typography>
                      <br />
                      <Box alignItems={"rigth"}>
                        {val.tipo &&
                          (val.tipo.includes("LIBRO") ||
                            val.tipo.includes("ARTICULO")) && (
                            <>
                              <Libro estado={val.estado} />
                            </>
                          )}
                        {val.tipo && val.tipo.includes("CERTIFICADO") && (
                          <>
                            <Certificado estado={val.estado} />
                          </>
                        )}
                        {val.tipo &&
                          !val.tipo.includes("CERTIFICADO") &&
                          !val.tipo.includes("LIBRO") &&
                          !val.tipo.includes("ARTICULO") && (
                            <>
                              <Otros estado={val.estado} />
                            </>
                          )}
                        {val.certificado && val.certificado !== "" && (
                          <Button
                            onClick={() => {
                              const link = document.createElement("a");
                              link.download = val.certificado;
                              link.href =
                                "https://publimed.net/archivos/" +
                                val.certificado;
                              link.click();
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Descargar Certificado
                          </Button>
                        )}
                      </Box>
                    </Paper>
                  </Grid>
                ))
              : ""}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};
